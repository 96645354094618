import Container from 'components/container'
import Footer from 'components/footer'
import FullPageSpinner from 'components/full-page-spinner'
import Router from 'pages/router'
import React from 'react'
import { BsEnvelope } from 'react-icons/bs'
import { FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import { FiPhoneCall } from 'react-icons/fi'
import 'react-lazy-load-image-component/src/effects/blur.css'

function App() {
  return (
    <>
      <div className='bg-white'>
        <Container>
          <div className='py-2'>
            {/* <Link to='/'>
              <img src='https://aiva-ng.hibootstrap.com/assets/images/logo.png' alt='logo' />
            </Link> */}
            <div className='flex items-center justify-between flex-col lg:flex-row gap-y-4'>
              <div className='flex gap-4 font-medium text-slate-600'>
                <span>
                  <a href='' className='flex items-center gap-1 hover:text-primary'>
                    <FiPhoneCall /> (+20) 2 38367340/9
                  </a>
                </span>
                <span>
                  <a href='' className='flex items-center gap-1 hover:text-primary'>
                    <BsEnvelope /> info@asil.mw
                  </a>
                </span>
              </div>
              <div className='flex gap-6'>
                <div className='flex gap-x-4 items-center text-base text-primary'>
                  <a href='#'>
                    <FaFacebookF />
                  </a>
                  <a href=''>
                    <FaLinkedinIn />
                  </a>
                  <a href=''>
                    <FaYoutube />
                  </a>
                </div>
                <a
                  href='https://wa.me/+202383673409'
                  target='_blank'
                  className='flex items-center gap-2 rounded bg-primary text-white py-2 px-8 md:py-3 md:px-12'
                  rel='noreferrer'
                >
                  <FiPhoneCall /> (+20) 2 38367340/9
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <React.Suspense fallback={<FullPageSpinner />}>
        <Router />
      </React.Suspense>
      <Footer />
      <div className='fixed bottom-6 left-6 z-40 bg-white rounded-full'>
        <a href='https://wa.me/+201103824442' target='_blank' rel='noreferrer'>
          <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 24 24'>
            <path fill='none' d='M0 0h24v24H0z' />
            <path
              fill='rgba(13,193,67,1)'
              d='M2.004 22l1.352-4.968A9.954 9.954 0 012 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 01-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 00-.371.1 1.293 1.293 0 00-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 006.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 003.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 00.833-.231 4.83 4.83 0 00.383-.22s.043-.028.125-.09c.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 00-.177-.041.482.482 0 00-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 01-.368.13 1.416 1.416 0 01-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 01-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 01-1.02-1.268l-.059-.095a.923.923 0 01-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 00.263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 00-.403.004z'
            />
          </svg>
        </a>
      </div>
    </>
  )
}

export default App
