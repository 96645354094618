import React from 'react'
import { Route, Routes } from 'react-router-dom'

const Home = React.lazy(() => import('./home'))
const WhatWeDoService = React.lazy(() => import('./what-we-do-service-[id]'))
const OurCustomerProjectsId = React.lazy(() => import('./our-customer-projects-[id]'))
const OurCustomerProjectId = React.lazy(() => import('./our-customer-project-[id]'))

const Overview = React.lazy(() => import('./overview'))
const Strategy = React.lazy(() => import('./strategy'))
const BusinessModel = React.lazy(() => import('./business-model'))

const SolarAlternativeEnergy = React.lazy(() => import('./solar-alternative-energy'))
const SmartHomes = React.lazy(() => import('./smart-homes'))
const SoftwareApps = React.lazy(() => import('./software-apps'))
const DigitalMarketing = React.lazy(() => import('./digital-marketing'))

const OurWorks = React.lazy(() => import('./our-works'))
const Solutions = React.lazy(() => import('./solutions'))
const Partners = React.lazy(() => import('./partners'))
const Sustainability = React.lazy(() => import('./sustainability'))

const MediaCenter = React.lazy(() => import('./media-center'))
const AfroAcademy = React.lazy(() => import('./afro-academy'))
const Career = React.lazy(() => import('./career'))
const Suppliers = React.lazy(() => import('./suppliers'))
const Investors = React.lazy(() => import('./investors'))

const TermsAndCondition = React.lazy(() => import('./terms-and-condition'))
const PrivacyAndPolicy = React.lazy(() => import('./privacy-and-policy'))

const NotFound = React.lazy(() => import('./not-found'))
const ContactUs = React.lazy(() => import('./contact-us'))

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/service/:serviceId' element={<WhatWeDoService />} />
      <Route path='/our-customer/:projectsId' element={<OurCustomerProjectsId />} />
      <Route path='/our-customer/projects/:id' element={<OurCustomerProjectId />} />

      <Route path='/about/overview' element={<Overview />} />
      <Route path='/about/strategy' element={<Strategy />} />
      <Route path='/about/business-model' element={<BusinessModel />} />

      <Route path='/industry/solar-alternative-energy' element={<SolarAlternativeEnergy />} />
      <Route path='/industry/smart-homes' element={<SmartHomes />} />
      <Route path='/industry/software-apps' element={<SoftwareApps />} />
      <Route path='/industry/digital-marketing' element={<DigitalMarketing />} />

      <Route path='/our-works' element={<OurWorks />} />
      <Route path='/solutions' element={<Solutions />} />
      <Route path='/partners' element={<Partners />} />
      <Route path='/sustainability' element={<Sustainability />} />

      <Route path='/media-center' element={<MediaCenter />} />
      <Route path='/afro-academy' element={<AfroAcademy />} />
      <Route path='/career' element={<Career />} />
      <Route path='/suppliers' element={<Suppliers />} />
      <Route path='/investors' element={<Investors />} />

      <Route path='/terms-and-condition' element={<TermsAndCondition />} />
      <Route path='/privacy-and-policy' element={<PrivacyAndPolicy />} />

      <Route path='/contact-us' element={<ContactUs />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}
